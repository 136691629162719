export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'PATIENT_FORM_LOADED':
      return {
        data: action.data
      }
    default:
      return state
  }
}

export const patientFormLoaded = data => ({ type: 'PATIENT_FORM_LOADED', data })
