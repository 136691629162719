import axios from 'axios'

export const getAddressFromCEP = async cep => {
  const response = await axios.get('https://viacep.com.br/ws/' + cep + '/json/')
  return response.data
}

export const getGeoFromAddress = address => {
  var geocoder = new google.maps.Geocoder() /*eslint-disable-line */

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address: address.postal_code.replace('-', '') }, (results, status) => {
      if (status === 'OK') {
        return resolve(results)
      } else {
        geocoder.geocode({ address: address.street + ' ' + address.city }, function(
          results,
          status
        ) {
          if (status === 'OK') {
            return resolve(results)
          } else {
            reject({
              Error: 'Não foi possível encontrar a localização'
            }) /*eslint-disable-line */
          }
        })
      }
    })
  })
}

export const getNavigatorLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        err => resolve(err)
      )
    } else {
      reject() /*eslint-disable-line */
    }
  })
}

export const getLocation = async location =>
  axios.get(`https://geocode.xyz/${location.latitude},${location.longitude}?geoit=json`)
