import React from 'react'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

const Notification = () => (
  <ToastContainer
    autoClose={2000}
    draggable
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick
    closeButton={false}
    theme='colored'
  />
)

export { Notification }
