import { useEffect } from 'react'

export const useWindowMessage = events => {
  useEffect(() => {
    const onMessage = handler => async event => {
      try {
        await handler?.(event.detail)
      } catch (exception) {}
    }

    const subscriptions = Object.entries(events).reduce(
      (subscriptions, [name, handler]) => ({ ...subscriptions, [name]: onMessage(handler) }),
      {}
    )

    for (const [name, handler] of Object.entries(subscriptions)) {
      window.addEventListener(name, handler)
    }

    return () => {
      for (const [name, handler] of Object.entries(subscriptions)) {
        window.removeEventListener(name, handler)
      }
    }
  }, [events])
}
