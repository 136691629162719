import FavoriteProvider from './FavoriteProvider'
import OnboardingStepProvider from './OnboardingStepProvider'
import FeatureFlagsProvider from './FeatureFlagsProvider'
import { CouponProvider } from './CouponProvider'
import { UserProvider } from './UserProvider'
import TimezoneProvider from './TimezoneProvider'
import { QueryClientProvider } from './QueryClientProvider'
import ApolloClientProvider from './ApolloClientProvider'
import CreditCardsProvider from './CreditCardsProvider'

import { theme } from 'utils/theme'
import { ThemeProvider } from 'styled-components'

const Provider = ({ children }) => (
  <ApolloClientProvider>
    <TimezoneProvider>
      <QueryClientProvider>
        <UserProvider>
          <FeatureFlagsProvider>
            <CouponProvider>
              <ThemeProvider theme={theme}>
                <OnboardingStepProvider>
                  <FavoriteProvider>
                    <CreditCardsProvider>{children}</CreditCardsProvider>
                  </FavoriteProvider>
                </OnboardingStepProvider>
              </ThemeProvider>
            </CouponProvider>
          </FeatureFlagsProvider>
        </UserProvider>
      </QueryClientProvider>
    </TimezoneProvider>
  </ApolloClientProvider>
)

export default Provider
