import {
  loginPatient,
  loginPsychologist,
  getCredentials,
  getUser,
  setProfile,
  setTokens,
  clear,
  logout
} from '../../services/auth'
import { CLEAR_SCHEDULE } from './consultation'
import { updatePhoto } from '../../services/userService'
import { saveMatchMakerIds } from '../../services/match-maker'
import { getSocialThumbByBrowser } from '../../utils/helpers'
import { postLikeProfessionalsIds } from '../../services/favorites'
import { setCookie } from 'nookies'

const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const BLOCK_LINKS_IN_HEADER = 'BLOCK_LINKS_IN_HEADER'
const LOADING_LOGIN = 'LOADING_LOGIN'
const LOGOUT = 'LOGOUT'
const UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO'
const UPDATE_PARTNERSHIPS_LIST = 'UPDATE_PARTNERSHIPS_LIST'

const INITIAL_STATE = {
  loading: false,
  error: false,
  user: null,
  blockLinksInHeader: false,
  partnershipsList: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING_LOGIN:
      return {
        ...state,
        loading: action.payload
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case LOGOUT:
      return {
        loading: false,
        error: false,
        user: undefined
      }
    case UPDATE_PROFILE_PHOTO:
      return {
        ...state,
        user: {
          ...state.user,
          social_thumb: action.payload.social_thumb
        }
      }
    case BLOCK_LINKS_IN_HEADER:
      return {
        ...state,
        blockLinksInHeader: action.payload
      }
    case UPDATE_PARTNERSHIPS_LIST:
      return {
        ...state,
        partnershipsList: action.payload
      }
    default:
      return state
  }
}

export const loginPat = credentials => dispatch => {
  const newCredentials = {
    username: credentials.username,
    password: credentials.password,
    grant_type: 'password'
  }
  const clearTrack = credentials.clearTrack ?? true

  return new Promise((resolve, reject) => {
    dispatch({ type: LOADING_LOGIN, payload: true })
    getCredentials()
      .then(res => {
        loginPatient({ ...newCredentials, ...res.data.results[0] })
          .then(res => {
            if (res) {
              localStorage.removeItem('@vittude:match-maker')
              setTokens(res)
              getUser(clearTrack)
                .then(async response => {
                  dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                      ...response.data.content.user,
                      social_thumb: getSocialThumbByBrowser(response.data.content.user)
                    }
                  })
                  try {
                    const matchmakingId = JSON.parse(localStorage.getItem('idMatchmaking'))
                    if (matchmakingId) {
                      await saveMatchMakerIds({
                        session_ids: matchmakingId,
                        id: response.data.content.user.id
                      })
                      localStorage.removeItem('idMatchmaking')
                    }
                  } catch (error) {
                    console.error('saving match maker ids error', error)
                  }
                  setCookie(
                    null,
                    '@vittude:user',
                    JSON.stringify({
                      contract: response.data.content.user.contract,
                      id: response.data.content.user.id
                    }),
                    {
                      maxAge: 30 * 24 * 60 * 60,
                      path: '/',
                      secure: process.env.NODE_ENV === 'production'
                    }
                  )
                  setProfile({
                    ...response.data.content.user,
                    social_thumb: getSocialThumbByBrowser(response.data.content.user)
                  })
                  try {
                    if (Boolean(localStorage.getItem('likeProfessionals'))) {
                      const likeProfessionalsIds = JSON.parse(
                        localStorage.getItem('likeProfessionals')
                      ).map(professional => professional.id)
                      await postLikeProfessionalsIds({ professional_ids: likeProfessionalsIds })
                      localStorage.removeItem('likeProfessionals')
                    }
                  } catch (error) {
                    console.log(error)
                  }

                  dispatch({ type: LOADING_LOGIN, payload: false })
                  resolve(response)
                })
                .catch(err => {
                  // clear()
                  // reject(err?.response?.data?.log_content?.error_code)
                })
            } else {
              clear()
              reject({ status: 401 }) /*eslint-disable-line */
            }
          })
          .catch(err => {
            clear()
            reject(err?.response?.data?.log_content?.error_code || err)
          })
      })
      .catch(error => {
        reject(error)
        clear()
      })
  })
}

export const loginPsycho = credentials => dispatch => {
  const { cpf_cnpj, password } = credentials
  const newCredentials = {
    username: cpf_cnpj.replace(new RegExp('[^0-9]', 'g'), ''),
    grant_type: 'password',
    password
  }
  return new Promise((resolve, reject) => {
    dispatch({ type: LOADING_LOGIN, payload: true })
    getCredentials()
      .then(res => {
        loginPsychologist({ ...newCredentials, ...res.data.results[0] })
          .then(res => {
            if (res) {
              setTokens(res)
              getUser().then(response => {
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: {
                    ...response.data.content.user,
                    social_thumb: getSocialThumbByBrowser(response.data.content.user)
                  }
                })
                setProfile({
                  ...response.data.content.user,
                  social_thumb: getSocialThumbByBrowser(response.data.content.user)
                })
                dispatch({ type: LOADING_LOGIN, payload: false })
                resolve()
              })
            } else {
              clear()
              reject({ status: 401 }) /*eslint-disable-line */
            }
          })
          .catch(err => {
            clear()
            reject(err.response.data?.log_content?.error_code || err)
          })
      })
      .catch(error => {
        /*eslint-disable-line */
        clear()
      })
  })
}

export const loadUser = () => dispatch => {
  return new Promise((resolve, reject) => {
    getUser()
      .then(async res => {
        await dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...res.data.content.user,
            social_thumb: getSocialThumbByBrowser(res.data.content.user)
          }
        })
        setProfile({
          ...res.data.content.user,
          social_thumb: getSocialThumbByBrowser(res.data.content.user)
        })
        resolve()
      })
      .catch(error => {
        reject(error.response?.data.detail)
        clear()
      })
  })
}

export const setBlockLinksInHeader = value => {
  return {
    type: BLOCK_LINKS_IN_HEADER,
    payload: value
  }
}

export const logoutUser = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    await logout()
    localStorage.removeItem('@vittude:match-maker')
    dispatch({ type: CLEAR_SCHEDULE })
    dispatch({ type: LOGOUT })
    resolve()
  })
}

export const updateProfilePhoto = (dataUrl, name) => dispatch => {
  return new Promise((resolve, reject) => {
    updatePhoto(dataUrl, name)
      .then(res => {
        dispatch({ type: UPDATE_PROFILE_PHOTO, payload: res.data.content })
        resolve(res.data.content)
      })
      .catch(error => reject(error))
  })
}

export const setPartnershipsList = value => {
  return {
    type: UPDATE_PARTNERSHIPS_LIST,
    payload: value
  }
}
