export const INCIDENT_OFF_ICON = '/static/images/signal_cellular_alt.svg'
export const INCIDENT_ON_WARNING_ICON = '/static/images/alert-icon.svg'
export const INCIDENT_ON_ERROR_ICON = '/static/images/alert-error-icon.svg'

const ALERT_CIRCLE_ICON = '/static/images/alert-icon-circle.svg'
const FIXED_INCIDENT_ICON = '/static/images/check-ok-icon.svg'
const ERROR_INCIDENT_ICON = INCIDENT_ON_ERROR_ICON

export const DEFAULT_INCIDENTS_STATE = [
  {
    title: 'Todos os sistemas normais',
    description: '',
    expected_end_date: null,
    type: 'GREEN'
  }
]

const INCIDENT_ICON_MAP = {
  GREEN: FIXED_INCIDENT_ICON,
  YELLOW: ALERT_CIRCLE_ICON,
  RED: ERROR_INCIDENT_ICON
}

export const getIconForIncident = incident => {
  const { type } = incident
  return INCIDENT_ICON_MAP[type]
}

export const formatForecast = incident => {
  const { resolved, expected_end_date: dateTimeAsString } = incident

  if (resolved) {
    return 'Resolvido'
  }

  if (dateTimeAsString === null) {
    return ''
  }

  const currentDate = new Date()
  const forecastDate = new Date(dateTimeAsString)

  const deltaInMiliseconds = forecastDate - currentDate
  const deltaInHours = Math.ceil(deltaInMiliseconds / (1000 * 60 * 60))

  if (deltaInHours <= 1) {
    if (deltaInHours >= 0) {
      return 'Previsão: 1 hora'
    }
    return ''
  }

  return `Previsão: ${deltaInHours} hora${deltaInHours > 1 ? 's' : ''}`
}

export const formatDescription = description => {
  if (Boolean(description) === false) {
    return 'Tudo certo por aqui.'
  }

  let newText = ''
  const text = description.split('\n')

  if (text) {
    newText = text?.map(item => item.replace('\r', '')).filter(item => item !== '')
  }

  return newText
}

export const getHigherIncidentState = incidents => {
  const currentIncidentsStatus = {}

  for (const incident of incidents) {
    currentIncidentsStatus[incident.type] = true
  }

  if (currentIncidentsStatus['RED']) return 'RED'
  if (currentIncidentsStatus['YELLOW']) return 'YELLOW'
}
