import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import { setupCache } from 'axios-cache-adapter'

const configure = async () => {
  await localforage.defineDriver(memoryDriver)
}

configure()

const URL_CACHED = [
  '/symptom/',
  '/professional_skill/',
  '/get_questions_matchmaking/',
  '/get_evaluations/'
]

const getURLCached = req => {
  const { url } = req
  const urlMapped = URL_CACHED.map(pattern => {
    const patternRegexp = new RegExp(pattern)
    return patternRegexp.test(url)
  })
  const shouldCached = urlMapped.includes(true)
  return !shouldCached
}

export const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: false,
    filter: req => getURLCached(req),
    methods: ['post', 'put', 'patch', 'delete']
  },
  store: localforage.createInstance({
    name: 'axios-cache',
    driver: [memoryDriver._driver, localforage.INDEXEDDB, localforage.LOCALSTORAGE]
  })
})
