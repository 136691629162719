import React from 'react'
import styled, { keyframes } from 'styled-components'

import { useBodyScrollLock } from 'hooks'

import { Row } from '../Grid'
import { NextImage } from '../NextImage'

import { getCurrentYear } from 'utils/helpers'
import { Text } from '../Text'

const FullScreenLoader = ({ propsImg, propsIcon }) => {
  useBodyScrollLock(true)
  return (
    <Row
      position='fixed'
      background='white'
      width='100%'
      height='100%'
      flexDirection='column'
      justifyContent='center'
      top={0}
      zIndex='9'
    >
      <Row flexDirection='column' flex={[20, 1]} justifyContent='center'>
        <NextImage src='/static/images/logo-vittude.svg' width={150} height={50} {...propsImg} />
        <Row mt='15px' justifyContent='center'>
          <Loading
            src='/static/images/match-maker/loader.svg'
            width={50}
            height={50}
            {...propsIcon}
          />
        </Row>
      </Row>
      <Row justifyContent='center' flex={[1, 'none']}>
        <Text color='#7f8c8d' textAlign='center'>
          Copyright © {getCurrentYear()} Vittude
        </Text>
      </Row>
    </Row>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loading = styled(NextImage)`
  animation: ${rotate} 2s linear infinite;
`

export { FullScreenLoader }
