import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const useFeaturesSnapshot = (segments, options) =>
  useQuery(
    ['use-features-snapshot', segments],
    async () => {
      const payload = await axios.post('/api/firebase/get-features-snapshot', {
        segments
      })

      return payload.data
    },
    {
      ...options,
      initialData: {}
    }
  )
