import { useContext } from 'react'

import TimezoneContext from '../context/timezoneContext'
import { useLocalStorage } from 'hooks'
import { getTimezone } from 'utils/helpers'

export const useTimezone = () => useContext(TimezoneContext)

const TimezoneProvider = ({ children }) => {
  const initialTimezone = getTimezone()

  const [timezone, setTimezone] = useLocalStorage('timezone', initialTimezone)
  const [timezoneGTM, setTimezoneGTM] = useLocalStorage('timezoneGTM', -3)

  return (
    <TimezoneContext.Provider value={{ timezone, setTimezone, timezoneGTM, setTimezoneGTM }}>
      {children}
    </TimezoneContext.Provider>
  )
}

export default TimezoneProvider
