import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import api from 'services/api'

import { formatParams, loadingGrid } from 'utils/formatters'
import { getSocialThumbByBrowser } from 'utils/helpers'

export const getSymptoms = async () => await api.get('/symptom/')

export const getLanguages = async () => await api.get('/get_languages/')

export const findPsychologists = async searchParams => {
  const searchResults = await api.post('/v1/professionals/search/', formatParams(searchParams))

  const psychologists = loadingGrid(searchResults.data.results)

  const psychologistsWithSocialThumb = psychologists.map(psychologist => ({
    ...psychologist,
    social_thumb: getSocialThumbByBrowser(psychologist)
  }))

  const result = {
    ...searchResults.data,
    results: psychologistsWithSocialThumb
  }

  return result
}

export const loadSkills = async () => await api.get('/professional_skill/')

export const useSymptoms = (options = {}) =>
  useQuery(['get-symptoms'], getSymptoms, {
    ...options,
    select: ({ data }) => data || []
  })

export const useProfessionalSkills = (options = {}) =>
  useQuery(['get-professional-skills'], loadSkills, { ...options, select: ({ data }) => data })

export const useSearchPsychologists = (params = {}, options = {}) =>
  useInfiniteQuery(['get-psychologists'], () => findPsychologists(params), {
    ...options,
    getNextPageParam: last => last?.next,
    getPreviousPageParam: first => first?.previous
  })
