import { scheduleConsultation } from '../../services/schedule'

const CONSULTATION_LOADING = 'CONSULTATION_LOADING'
const SET_PARTNERSHIP = 'SET_PARTNERSHIP'
const CONSULTATION_SUCCESS = 'CONSULTATION_SUCCESS'
const CONSULTATION_FAIL = 'CONSULTATION_FAIL'
const INIT_SCHEDULE = 'INIT_SCHEDULE'
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE'
const SET_COUPON = 'SET_COUPON'

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: null,
  coupon: null,
  partnership: null
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONSULTATION_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CONSULTATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null
      }
    case CONSULTATION_FAIL:
      return {
        ...state,
        error: action.payload
      }
    case INIT_SCHEDULE:
      return {
        ...state,
        data: action.payload
      }
    case CLEAR_SCHEDULE:
      return {
        data: null,
        loading: false,
        error: null
      }
    case SET_COUPON: {
      return {
        ...state,
        coupon: action.coupon
      }
    }
    case SET_PARTNERSHIP: {
      return {
        ...state,
        partnership: action.partnership
      }
    }
    default:
      return state
  }
}

export const schedule = (details, user_id) => dispatch => {
  /*eslint-disable-line */
  dispatch({ type: CONSULTATION_LOADING, payload: true })
  return new Promise((resolve, reject) => {
    scheduleConsultation(details, user_id)
      .then(res => {
        dispatch({ type: CONSULTATION_LOADING, payload: false })
        dispatch({ type: CONSULTATION_SUCCESS, payload: res })
        resolve(res)
      })
      .catch(async error => {
        await dispatch({ type: CONSULTATION_LOADING, payload: false })
        await dispatch({
          type: CONSULTATION_FAIL,
          payload: error.response.data
        })
        reject(error.response.data)
      })
  })
}

export const init = schedule => dispatch => {
  return new Promise(async (resolve, reject) => {
    await dispatch({ type: INIT_SCHEDULE, payload: schedule })
    resolve()
  })
}

export const clear = () => dispatch => {
  dispatch({ type: CLEAR_SCHEDULE })
}

export const setCoupon = coupon => dispatch => {
  dispatch({ type: SET_COUPON, coupon })
}

export const setPartnership = partnership => dispatch => {
  dispatch({ type: SET_PARTNERSHIP, partnership })
}
