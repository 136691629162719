import { useMemo, useContext } from 'react'

import CreditCardContext from 'context/creditCardsContext'
import { useUser } from 'providers/UserProvider'

import { useGetCreditCards } from 'services/credit-card'
import {
  usePaymentMethodsInfo,
  initialPaymentMethodsInfoParams
} from 'services/professionalService'

export const useCreditCards = () => useContext(CreditCardContext)

const CreditCardProvider = ({ children }) => {
  const { user, isProfessional } = useUser()

  const { data: paymentMethodsInfo } = usePaymentMethodsInfo(initialPaymentMethodsInfoParams, {
    enabled: !!user && !isProfessional
  })

  const {
    data: cards,
    isLoading: isGettingCreditCards,
    isError: hasFailedToGetCreditCards,
    refetch: refetchCreditCards
  } = useGetCreditCards({
    enabled: !!user && user?.role !== 'professional',
    initialData: []
  })

  const favoriteCreditCard = useMemo(() => cards?.find(card => card?.is_favorite), [cards])

  const isAllowedToPurchaseWithCreditCard = useMemo(
    () => !!favoriteCreditCard && paymentMethodsInfo?.credit_card?.available,
    [favoriteCreditCard, paymentMethodsInfo]
  )

  return (
    <CreditCardContext.Provider
      value={{
        cards,
        isGettingCreditCards,
        hasFailedToGetCreditCards,
        favoriteCreditCard,
        refetchCreditCards,
        isAllowedToPurchaseWithCreditCard
      }}
    >
      {children}
    </CreditCardContext.Provider>
  )
}

export default CreditCardProvider
