import { useRef, useCallback } from 'react'

export const useDebouncedCallback = (callback, timeoutInMilliseconds = 200) => {
  const timeout = useRef()

  return useCallback(
    async (...params) => {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => callback(...params), timeoutInMilliseconds)
    },
    [callback, timeoutInMilliseconds]
  )
}
