export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'LOAD_SIGNUP':
      return {
        data: action.data
      }
    case 'CLEAR_SIGNUP':
      return {
        data: undefined
      }
    default:
      return state
  }
}

export const loadSignUp = data => ({ type: 'LOAD_SIGNUP', data })
export const clearSignUp = () => ({ type: 'CLEAR_SIGNUP' })
