import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { updateFilter } from '../redux/modules/filters'

import usePrevious from './usePrevious'

const override = (a, b) =>
  Object.keys(a).reduce((object, key) => {
    try {
      return { ...object, [key]: JSON.parse(b[key] || a[key]) }
    } catch (error) {
      return { ...object, [key]: b[key] || a[key] }
    }
  }, a)

const areEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export const useMutateFiltersWithAvailableQueryParameters = (filters, query) => {
  const dispatch = useDispatch()

  const previousQuery = usePrevious(query)

  useEffect(() => {
    if (!Object.keys(query).length || areEqual(query, previousQuery)) {
      return
    }

    const value = override(filters, query)

    dispatch(updateFilter(value))
  }, [filters, query, previousQuery])
}
