import React, { useContext } from 'react'

import FeatureFlagsContext from 'context/featureFlagsContext'
import { useUser } from '../providers/UserProvider'

import { useAccountSegments, useFeaturesSnapshot } from '../hooks'

const FeatureFlagsProvider = ({ children }) => {
  const { user } = useUser()

  const { isFetched, data: segments } = useAccountSegments({
    id: user?.id,
    user_email: user?.email,
    user_contract_id: user?.contract?.id,
    user_company_name: user?.contract?.company
  })
  const { data: features, refetch } = useFeaturesSnapshot(segments, { enabled: isFetched })

  return (
    <FeatureFlagsContext.Provider value={{ features, getFeatures: refetch }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlags = () => useContext(FeatureFlagsContext)

export default FeatureFlagsProvider
