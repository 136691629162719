import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import psychos from './psychos'
import filters from './filters'
import symptoms from './symptoms'
import skills from './skills'
import auth from './auth'
import consultation from './consultation'
import signUp from './signUp'
import step2 from './step2Payment'
import loadPatientForm from './loadPatientForm'
import loadScheduleForm from './loadScheduleForm'
import bankAccount from './bankAccount'
import timezone from './timezone'
import patientRecords from './patientRecords'
import languages from './languages'
import calendar from './calendar'

const rootReducer = combineReducers({
  form: formReducer,
  psychos,
  bankAccount,
  filters,
  symptoms,
  skills,
  auth,
  consultation,
  signUp,
  step2,
  loadPatientForm,
  patientRecords,
  loadScheduleForm,
  timezone,
  languages,
  calendar
})

export default rootReducer
