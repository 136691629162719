import { useQuery } from '@tanstack/react-query'
import {
  INCIDENT_OFF_ICON,
  INCIDENT_ON_ERROR_ICON,
  INCIDENT_ON_WARNING_ICON,
  getHigherIncidentState
} from 'components/v2/Header/utils/beacon-utils'
import { getIncidents } from 'services/beacon'

const getBeaconData = async timezone => {
  const { data: response } = await getIncidents(timezone)

  let icon = INCIDENT_OFF_ICON
  const beacons = response?.data?.beacons || []
  const higherIncidentState = getHigherIncidentState(beacons)
  const isHavingIncidents = beacons?.length > 0 && beacons?.some(item => item.type !== 'GREEN')
  const hasErrorIncident = higherIncidentState === 'RED'

  if (isHavingIncidents) {
    icon = INCIDENT_ON_WARNING_ICON
    if (hasErrorIncident) {
      icon = INCIDENT_ON_ERROR_ICON
    }
  } else {
    icon = INCIDENT_OFF_ICON
  }

  const data = {
    beaconsIncidents: beacons,
    isHavingIncidents: isHavingIncidents,
    hasErrorIncident: hasErrorIncident,
    icon: icon
  }

  return data
}

export const useBeaconIncidents = (userIsLogged, timezone, userIsProfessional) =>
  useQuery(['get-beacon-incidents'], () => getBeaconData(timezone), {
    refetchOnWindowFocus: true,
    refetchInterval: 5 * (60 * 1000),
    enabled: !!userIsLogged && !!userIsProfessional
  })
