import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  space,
  layout,
  typography,
  border,
  background,
  color,
  variant,
  position,
  system
} from 'styled-system'
import propTypes from '@styled-system/prop-types'

const BIGGEST = 'biggest'
const BIGGER = 'bigger'
const BIG = 'big'
const MEDIUM = 'medium'
const REGULAR = 'regular'
const SMALL = 'small'
const TINY = 'tiny'
const TEENY = 'teeny'

const Text = styled.p(
  variant({
    prop: 'lineClamp',
    variants: {
      false: {
        fontSize: 4,
        lineHeight: 'heading'
      },
      true: {
        display: '-webkit-box',
        '-webkit-line-clamp': '3', //TODO: abilities dinamic number of lines
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
      }
    }
  }),
  variant({
    variants: {
      [BIGGEST]: {
        fontSize: 42
      },
      [BIGGER]: {
        fontSize: 24
      },
      [BIG]: {
        fontSize: 20
      },
      [MEDIUM]: {
        fontSize: 18
      },
      [REGULAR]: {
        fontSize: 16
      },
      [SMALL]: {
        fontSize: 14
      },
      [TINY]: {
        fontSize: 12
      },
      [TEENY]: {
        fontSize: 9
      }
    }
  }),
  ({ textDecoration }) => `
    margin: 0px;
    text-decoration: ${textDecoration};
  `,
  space,
  layout,
  typography,
  border,
  background,
  color,
  position,
  system({ cursor: true })
)

Text.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf([BIGGEST, BIGGER, BIG, MEDIUM, REGULAR, SMALL, TINY, TEENY])),
    PropTypes.oneOf([BIGGEST, BIGGER, BIG, MEDIUM, REGULAR, SMALL, TINY, TEENY])
  ]),
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.border,
  ...propTypes.background,
  ...propTypes.color,
  ...propTypes.position
}

Text.defaultProps = {
  variant: 'regular',
  fontFamily: 'Sora',
  textDecoration: 'none',
  lineClamp: false
}

export { Text }
