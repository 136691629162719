import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export const useRouteTransition = () => {
  const { events } = useRouter()

  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    const onStart = (_, { shallow }) => {
      if (shallow) {
        return
      }

      setIsTransitioning(true)
    }

    const onStop = () => {
      setIsTransitioning(false)
    }

    events.on('routeChangeStart', onStart)
    events.on('routeChangeComplete', onStop)
    events.on('routeChangeError', onStop)

    return () => {
      events.off('routeChangeStart', onStart)
      events.off('routeChangeComplete', onStop)
      events.off('routeChangeError', onStop)
    }
  }, [])

  return { isTransitioning }
}
