import { loadSkills } from '../../services/handleRequests'

const SKILLS = 'SKILLS'
const SKILLS_LOADING = 'SKILLS_LOADING'
const SKILLS_ERROR = 'SKILLS_ERROR'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SKILLS:
      return {
        ...state,
        data: action.payload
      }
    case SKILLS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SKILLS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export const getSkills = () => dispatch => {
  dispatch({ type: SKILLS_LOADING, payload: true })
  loadSkills()
    .then(res => {
      dispatch({ type: SKILLS, payload: res.data })
      dispatch({ type: SKILLS_LOADING, payload: false })
    })
    .catch(err => {
      dispatch({ type: SKILLS_ERROR, payload: err })
      dispatch({ type: SKILLS_LOADING, payload: false })
    })
}
