import { useCallback, useMemo } from 'react'
import { useFeatureFlags } from 'hooks'
import { Widget } from '@typeform/embed-react'
import { useUser } from 'providers/UserProvider'

import { respondProgramForm } from 'services/vittude-care-api'

const VittudeCareForm = ({ formId }) => {
  const {
    user,
    isUserLoggedIn,
    isProfessional,
    shouldAnswerVitCare,
    setShouldAnswerVitCare,
    isUserB2B
  } = useUser()

  const { frontend_is_vittude_care_program_feature_enabled } = useFeatureFlags(
    'frontend_is_vittude_care_program_feature_enabled'
  )

  const onSubmit = useCallback(async ({ responseId }) => {
    try {
      await respondProgramForm(user?.id, formId, responseId)
    } catch (error) {
      console.log(error)
    } finally {
      setShouldAnswerVitCare(false)
    }
  }, [])

  const isVittudeCareFeatureEnabled =
    frontend_is_vittude_care_program_feature_enabled === true &&
    frontend_is_vittude_care_program_feature_enabled !== undefined

  const shouldShowWidget = useMemo(() => {
    return (
      isUserLoggedIn &&
      isVittudeCareFeatureEnabled &&
      !isProfessional &&
      shouldAnswerVitCare &&
      isUserB2B
    )
  }, [isUserLoggedIn, isVittudeCareFeatureEnabled, isProfessional, shouldAnswerVitCare, isUserB2B])

  if (!shouldShowWidget) {
    return null
  }

  return (
    <Widget
      id={formId}
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999
      }}
      onSubmit={onSubmit}
    />
  )
}

export default VittudeCareForm
