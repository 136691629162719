import { useState, useEffect, useMemo } from 'react'

const breakpoints = [640, 832]

export const useDevice = () => {
  const [width, setWidth] = useState(1280)

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.screen.width)
    }
    window.addEventListener('resize', updateDimensions)

    updateDimensions()
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const screenSize = useMemo(() => {
    if (width <= breakpoints[0]) return 'Mobile'
    if (width <= breakpoints[1]) return 'Tablet'

    return 'Desktop'
  }, [width])

  const isMobile = useMemo(() => screenSize === 'Mobile', [screenSize])
  const isTablet = useMemo(() => screenSize === 'Tablet', [screenSize])

  return { isMobile, isTablet, screenSize }
}
