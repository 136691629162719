import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Hydrate } from '@tanstack/react-query'
import { useRouteTransition, useSendInteractionToNewRelic, useVersion } from 'hooks'

import { wrapper } from '../redux/store'
import Provider from 'providers'
import { GlobalStyle } from 'utils/theme'

import { Notification } from 'components/v2/Notification'
import { VittudeCareForm } from 'components/v2/VittudeCareForm'
import { FullScreenLoader } from 'components/v2/FullScreenLoader'

export default function App({ Component, pageProps, err, router, ...rest }) {
  useVersion()
  useSendInteractionToNewRelic()

  const { isTransitioning } = useRouteTransition()
  const { store, props } = wrapper.useWrappedStore({ ...rest, ...pageProps })

  return (
    <ReduxProvider store={store}>
      <Provider>
        <Hydrate state={pageProps.dehydratedState}>
          <VittudeCareForm formId={'DB1qXGg5'} />
          {isTransitioning && <FullScreenLoader />}
          <Component {...pageProps} {...props} err={err} url={{ ...router }} />
          <Notification />
          <GlobalStyle />
        </Hydrate>
      </Provider>
    </ReduxProvider>
  )
}
