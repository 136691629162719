import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import combinedReducer from './modules'

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }

  return applyMiddleware(...middleware)
}

const preserveSearchPageState = (state, action) => {
  action.payload.filters = state.filters
}

const reducers = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      preserveSearchPageState(state, action)

      return { ...state, ...action.payload }
    default:
      return combinedReducer(state, action)
  }
}

export const initStore = () => {
  return createStore(reducers, bindMiddleware([thunkMiddleware]))
}

export const wrapper = createWrapper(initStore)
