export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'PATIENT_RECORDS_LOADED':
      return {
        data: action.data
      }
    default:
      return state
  }
}

export const patientRecordsLoaded = data => ({
  type: 'PATIENT_RECORDS_LOADED',
  data
})
