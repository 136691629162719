export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'LOAD_BANK_FORM':
      return {
        data: {
          ...action.data,
          account_type: `${action.data.account_type}`
        }
      }
    default:
      return state
  }
}

export const loadBankAccount = data => dispatch => dispatch({ type: 'LOAD_BANK_FORM', data })
