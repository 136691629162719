const GUESS_TIMEZONE = 'GUESS_TIMEZONE'
const SET_TIMEZONE = 'SET_TIMEZONE'
const INITIAL_STATE = {
  data: ''
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GUESS_TIMEZONE:
      return {
        ...state,
        data: action.payload
      }
    case SET_TIMEZONE:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}
export const guessTimezone = () => {
  return dispatch => {
    dispatch({
      type: GUESS_TIMEZONE,
      payload: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Sao_Paulo'
    })
  }
}
export const setTimezone = timezone => {
  return dispatch => {
    dispatch({ type: SET_TIMEZONE, payload: timezone })
  }
}
