import { getLanguages } from '../../services/handleRequests'

const LOADING_LANGUAGES = 'LOADING_LANGUAGES'
const LANGUAGES = 'LANGUAGES'
const LANGUAGES_ERROR = 'LANGUAGES_ERROR'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING_LANGUAGES:
      return {
        ...state,
        loading: action.payload
      }
    case LANGUAGES:
      return {
        ...state,
        data: action.payload
      }
    case LANGUAGES_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      }
    default:
      return state
  }
}

export const loadLanguages = () => dispatch => {
  dispatch({ type: LOADING_LANGUAGES, payload: true })
  getLanguages()
    .then(res => {
      dispatch({ type: LANGUAGES, payload: res.data.content.countrycodes })
      dispatch({ type: LOADING_LANGUAGES, payload: false })
    })
    .catch(err => {
      dispatch({ type: LANGUAGES_ERROR, payload: err })
      dispatch({ type: LOADING_LANGUAGES, payload: false })
    })
}
