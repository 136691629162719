import { useQuery, useMutation } from '@tanstack/react-query'
import api from 'services/api'

export const getCreditCards = async () => {
  const response = await api.get('/credit-card/')
  return response?.data?.content || []
}

export const insertCreditCard = async payload => {
  const response = await api.post('/credit-card/', payload)
  return response?.data?.content
}

export const deleteCreditCard = id => api.delete(`/credit-card/${id}/`)

export const useGetCreditCards = (options = {}) =>
  useQuery(['get-credit-cards'], getCreditCards, { initialData: [], ...options })

export const useInsertCreditCard = (options = {}) => useMutation(insertCreditCard, { ...options })

export const useDeleteCreditCard = (options = {}) => useMutation(deleteCreditCard, { ...options })
