import { createContext } from 'react'

export default createContext({
  cards: [],
  isGettingCreditCards: false,
  hasFailedToGetCreditCards: false,
  refetchCreditCards: () => {},
  favoriteCreditCard: null,
  isAllowedToPurchaseWithCreditCard: false
})
