import { useEffect } from 'react'

export const useOuterClickNotifier = (
  onOuterClick,
  innerBoundaryRef,
  options = { event: 'click' }
) => {
  useEffect(() => {
    const handleClick = e => {
      if (innerBoundaryRef?.current?.contains(e.target)) {
        return
      }

      onOuterClick(e)
    }

    document.addEventListener(options?.event ?? 'click', handleClick)

    return () => document.removeEventListener(options?.event ?? 'click', handleClick)
  }, [onOuterClick, innerBoundaryRef])
}

export const useHotkey = (key, bindFunction) => {
  useEffect(() => {
    const handleClick = event => {
      if (event.key === key) {
        return bindFunction()
      }
    }
    document.addEventListener('keydown', handleClick)
    return () => document.removeEventListener('keydown', handleClick)
  }, [key, bindFunction])
}
