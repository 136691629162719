import OnboardingStepContext from '../context/onboardingStepContext'
import { useLocalStorage } from '../hooks'

const OnboardingStepProvider = ({ children }) => {
  const [onboardingStep, setOnboardingStep] = useLocalStorage('onboardingStep', '')
  return (
    <OnboardingStepContext.Provider value={{ onboardingStep, setOnboardingStep }}>
      {children}
    </OnboardingStepContext.Provider>
  )
}

export default OnboardingStepProvider
