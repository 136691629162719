export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'LOAD_SCHEDULE_FORM':
      return {
        data: action.data
      }
    default:
      return state
  }
}

export const loadSchedule = data => dispatch => dispatch({ type: 'LOAD_SCHEDULE_FORM', data })
