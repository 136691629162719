import { useEffect, useState } from 'react'

export const useDetectMobileDeviceOS = () => {
  const [deviceOS, setDeviceOS] = useState('')

  const detectOS = () => {
    const userAgent = navigator.userAgent

    if (/android/i.test(userAgent)) {
      setDeviceOS('Android')
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceOS('iOS')
    }
  }

  useEffect(() => {
    window && detectOS()
  }, [])

  return { isMobile: !!deviceOS, deviceOS }
}
