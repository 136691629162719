import moment from 'moment'

const UPDATE_FILTER = 'UPDATE_FILTER'
const UPDATE_FILTERS = 'UPDATE_FILTERS'
const ADD_FILTER_ARRAY = 'ADD_FILTER_ARRAY'
const DELETE_FILTER_ARRAY = 'DELETE_FILTER_ARRAY'
const UPDATE_BTN_FILTER = 'UPDATE_BTN_FILTER'
const COLLAPSE_FILTER = 'COLLAPSE_FILTER'
const RESET_FILTERS = 'RESET_FILTERS'
const INITIATE_FILTERS = 'INITIATE_FILTERS'

export const INITIAL_STATE = {
  address: '',
  age: '',
  symptoms: [],
  skills: [],
  lat: null,
  long: null,
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment()
    .add(4, 'days')
    .format('YYYY-MM-DD'),
  focusedInput: null,
  focusedInputMobile: 'startDate',
  indifferent: false,
  female: false,
  male: false,
  adult: false,
  kids: false,
  adolescent: false,
  couple: false,
  elderly: false,
  indifferentAvailable: true,
  available_in_the_morning: false,
  available_in_the_afternoon: false,
  available_at_night: false,
  accept_depression_free_consultation: false,
  accept_students: false,
  accept_partnerships: false,
  min_price: process.env.FILTER_VALUE_MIN,
  max_price: process.env.FILTER_VALUE_MAX,
  order: 'by_distance',
  timezone: 'America/Sao_Paulo',
  update: false,
  collapsed: false,
  seo_search: false,
  bank_slip_ok: false,
  payment: 'whatever',
  partnership: null,
  nameProfessional: '',
  languages_code: [],
  race: null,
  contract_id: null,
  consultation: 'online'
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_FILTER:
      if (
        typeof window !== 'undefined' &&
        window.document.referrer.includes('widgets/busca/20219dfe-13d2-4f4e-bd6b-be5d2d113c9c')
      ) {
        return {
          ...state,
          ...action.payload.obj,
          update: action.payload.activeBtnFilter,
          utm_source: 'blog',
          utm_medium: 'search_widget',
          utm_content: 'widget'
        }
      }
      return {
        ...state,
        ...action.payload.obj,
        update: action.payload.activeBtnFilter
      }
    case UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload
      }
    case RESET_FILTERS:
      return INITIAL_STATE
    case COLLAPSE_FILTER:
      return {
        ...state,
        collapsed: !state.collapsed
      }
    case UPDATE_BTN_FILTER:
      return {
        ...state,
        update: action.payload
      }
    case ADD_FILTER_ARRAY:
      const item = state[action.payload.where].find(text => text === action.payload.text)
      return {
        ...state,
        [action.payload.where]: item
          ? state[action.payload.where]
          : state[action.payload.where].concat(action.payload.text),
        update: item ? state.update : action.payload.activeBtnFilter
      }
    case DELETE_FILTER_ARRAY:
      const newState = [].concat(state[action.payload.where])
      newState.splice(action.payload.index, 1)
      return {
        ...state,
        [action.payload.where]: newState,
        update: action.payload.activeBtnFilter
      }
    case INITIATE_FILTERS:
      const parsed = JSON.parse(action.payload)
      return {
        ...parsed,
        update: false,
        collapsed: false
      }
    default:
      return state
  }
}

export const updateFilter = (obj, activeBtnFilter = false) => {
  return dispatch => {
    obj
      ? dispatch({ type: UPDATE_FILTER, payload: { obj, activeBtnFilter } })
      : dispatch({ type: UPDATE_BTN_FILTER, payload: activeBtnFilter })
  }
}

export const addTo = (where, text, activeBtnFilter = false) => {
  return dispatch => {
    dispatch({
      type: ADD_FILTER_ARRAY,
      payload: { where, text, activeBtnFilter }
    })
  }
}

export const removeTo = (where, index, activeBtnFilter = false) => {
  return dispatch => {
    dispatch({
      type: DELETE_FILTER_ARRAY,
      payload: { where, index, activeBtnFilter }
    })
  }
}

export const collapse = () => {
  return dispatch => {
    dispatch({ type: COLLAPSE_FILTER })
  }
}

export const resetFilters = () => {
  return dispatch => {
    dispatch({ type: RESET_FILTERS })
  }
}

export const initiateFilters = data => dispatch =>
  dispatch({ type: INITIATE_FILTERS, payload: data })
