import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_VITTUDE_CARE_GRAPHQL,
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_VITTUDE_CARE_GRAPHQL_TOKEN}`
  }
})

export default client
