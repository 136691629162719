import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'

import { space, layout, border, position, flex } from 'styled-system'

const NextImage = ({ ...props }) => <ImageComponent width='100%' height='100%' {...props} />

const ImageComponent = styled(Image)(space, layout, border, position, flex)

export { NextImage }
