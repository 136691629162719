import { useState, useEffect } from 'react'

export const useGeolocation = (onSuccess = () => {}, onFailure = () => {}) => {
  const [coordinates, setCoordinates] = useState(null)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        onSuccess(coords)
        setCoordinates(coords)
      },
      () => {
        onFailure()
        setCoordinates(null)
      }
    )
  }, [])

  return coordinates
}
