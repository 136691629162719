import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url('/static/fonts/OpenSans/open-sans-v17-latin-regular.woff2') format('woff2'),
      url('/static/fonts/OpenSans/open-sans-v17-latin-regular.woff') format('woff');
      font-display: swap;
    }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('/static/fonts/OpenSans/open-sans-v17-latin-600.woff2') format('woff2'),
      url('/static/fonts/OpenSans/open-sans-v17-latin-600.woff') format('woff');
      font-display: swap;
    }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url('/static/fonts/OpenSans/open-sans-v17-latin-700.woff2') format('woff2'),
      url('/static/fonts/OpenSans/open-sans-v17-latin-700.woff') format('woff');
      font-display: swap;
    }
  body {
    font-family: Fira Sans;
    margin: 0;
  }
  *, ::after, ::before {
    box-sizing: border-box;
  }
`

const theme = {
  colors: {
    primary: '#FF7708',
    darkPrimary: '#FF7708',
    mediumPrimary: '#e56048',
    lightPrimary: '#FF770880',
    secondary: '#313a4d',
    red: '#DB0000',
    green: '#27AE60',
    lightGreen: '#50af61',
    yellow: '#F7B206',
    blue: {
      n100: '#27A5E00F',
      n200: '#0056b3',
      n300: '#005EEB',
      n400: '#5A95EC',
      n500: '#294671'
    },
    lightBlue: '#27A5E0',
    paleBlue: '#E7F6EE',
    darkBlue: '#29374D',
    snow: '#F8F8F8',
    white: '#FFFFFF',
    lightGray: '#757474',
    darkGray: '#3C3C3C',
    salmon: '#FF3B80',
    gray: {
      n100: '#FCFCFC',
      n150: '#EFEEEE',
      n200: '#F5F5F5',
      n250: '#E7EAED',
      n275: '#ced2d5',
      n300: '#BDC3C7',
      n325: '#bbc1c4',
      n350: '#7D7D7D',
      n400: '#7F8C8D',
      n500: '#4E5657',
      n600: '#707070',
      n700: '#6B7778',
      n750: '#808c8d',
      n800: '#808080',
      n850: '#484848',
      n900: '#1C1C1C',
      n950: '#1E1E1E'
    },
    black: '#212121',
    orange: '#FF7708',
    lightOrange: '#FFF1EF',
    lightRed: '#FFEAEA',
    infoBackground: '#DDE7F2',
    infoText: '#294671',
    success: '#d8f3bc',
    info: '#CCE1FF',
    warning: '#fff0bd',
    error: '#ffccc7'
  },
  shadows: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    boxShadowSnow: '0px 12px #F8F8F8'
  }
}

const WidgetsGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Fira Sans;
    src: url('/static/fonts/FiraSans-Regular.ttf')
  }
  @font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/open-sans-v17-latin-regular.woff2') format('woff2'),
        url('/static/fonts/OpenSans/open-sans-v17-latin-regular.woff') format('woff');
  }
  body {
    font-family: Fira Sans;
    margin: 0;
    border: none;
  }
  *, ::after, ::before {
    box-sizing: border-box;
  }
`

export { GlobalStyle, theme, WidgetsGlobalStyle }
