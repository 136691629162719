import api from 'services/api'

export const getIntercomHash = () =>
  api.get('/get_intercom_hash/', {
    transformRequest: [
      (data, headers) => {
        headers['Content-Type'] = 'application/json;charset=UTF-9'
        return JSON.stringify(data)
      }
    ]
  })
