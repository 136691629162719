import FavoriteContext from '../context/favoriteContext'
import { useLocalStorage } from '../hooks'

const FavoriteProvider = ({ children }) => {
  const [likeProfessionals, setLikeProfessionals] = useLocalStorage('likeProfessionals', [])
  return (
    <FavoriteContext.Provider value={{ likeProfessionals, setLikeProfessionals }}>
      {children}
    </FavoriteContext.Provider>
  )
}

export default FavoriteProvider
