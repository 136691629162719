import { getSymptoms } from '../../services/handleRequests'

const LOADING_SYMPTOMS = 'LOADING_SYMPTOMS'
const SYMPTOMS = 'SYMPTOMS'
const SYMPTOMS_ERROR = 'SYMPTOMS_ERROR'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING_SYMPTOMS:
      return {
        ...state,
        loading: action.payload
      }
    case SYMPTOMS:
      return {
        ...state,
        data: action.payload
      }
    case SYMPTOMS_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      }
    default:
      return state
  }
}

export const loadSymptoms = () => dispatch => {
  dispatch({ type: LOADING_SYMPTOMS, payload: true })
  getSymptoms()
    .then(res => {
      dispatch({ type: SYMPTOMS, payload: res.data })
      dispatch({ type: LOADING_SYMPTOMS, payload: false })
    })
    .catch(err => {
      dispatch({ type: SYMPTOMS_ERROR, payload: err })
      dispatch({ type: LOADING_SYMPTOMS, payload: false })
    })
}
