import React, { forwardRef } from 'react'
import { Row } from './Row'

const Column = forwardRef((props, ref) => {
  const { sm, md, lg } = props
  //Flex Basis
  const smFlex = sm ? '0 0 ' + (sm * 100) / 12 + '%' : null
  const mdFlex = md ? '0 0 ' + (md * 100) / 12 + '%' : null
  const lgFlex = lg ? '0 0 ' + (lg * 100) / 12 + '%' : null
  //Max Width
  const smMax = sm ? (sm * 100) / 12 + '%' : null
  const mdMax = md ? (md * 100) / 12 + '%' : null
  const lgMax = lg ? (lg * 100) / 12 + '%' : null // skip certain breakpoints [100, null, 200]

  return (
    <Row
      ref={ref}
      minWidth='auto'
      maxWidth={sm || md || lg ? [smMax, mdMax, lgMax] : 'inherit'}
      flex={sm || md || lg ? [smFlex, mdFlex, lgFlex] : 'initial'}
      flexDirection='column'
      {...props}
    />
  )
})

export { Column }
