import { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'

export const useSendInteractionToNewRelic = () => {
  const { events } = useRouter()

  const onRouteChangeStart = useCallback(url => {
    const interaction = window?.newrelic?.interaction?.()

    interaction?.setName?.(url)
    interaction?.save?.()
  }, [])

  useEffect(() => {
    events.on('routeChangeStart', onRouteChangeStart)
    return () => events.off('routeChangeStart', onRouteChangeStart)
  }, [events])
}
