import axios from 'axios'
import Router from 'next/router'
import { destroyCookie, parseCookies } from 'nookies'

import { getToken, getRefreshToken, refreshTokenPatient, clear, getProfile } from './auth'
import { cache } from './cache'

export const __API__ = process.env.API

let cancelTokenSource = axios.CancelToken.source()

const fetchClient = () => {
  const defaultOptions = {
    baseURL: __API__,
    adapter: cache.adapter
  }

  let instance = axios.create(defaultOptions)
  let isRefreshing = false

  instance.interceptors.request.use(config => {
    config.cancelToken = cancelTokenSource.token
    config.headers.Authorization = getToken()

    const vittudeTotp = parseCookies()['vittude-totp']

    if (vittudeTotp) {
      config.headers['X-Vittude-Totp'] = vittudeTotp
    }

    return config
  })

  instance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config

      if (
        error?.response?.status === 401 &&
        (originalRequest.url === `${defaultOptions.baseURL}/token/` ||
          originalRequest.url === `${defaultOptions.baseURL}/prof_token/`)
      ) {
        return Promise.reject(error)
      }

      const refreshToken = getRefreshToken()
      const profile = getProfile()
      const isPatient = profile?.role === 'patient'
      const errorLogCode = error.response?.data?.log_content?.error_code

      if (errorLogCode === 'mfa_required') {
        destroyCookie(null, 'vittude-totp')

        Router.push('/autenticacao')
        return Promise.reject(error)
      }

      if (error?.response?.status === 401) {
        if (refreshToken && isPatient) {
          isRefreshing = true

          clear({ includeProfile: false })

          try {
            await refreshTokenPatient(refreshToken)
            return await instance.request(originalRequest)
          } catch (refreshError) {
            clear()
            Router.push('/')
            return Promise.reject(refreshError)
          } finally {
            isRefreshing = false
          }
        }

        clear()

        if (!isRefreshing) {
          Router.push('/')
        }

        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
  )

  return instance
}

export const cancelAllRequests = () => {
  cancelTokenSource.cancel('Interrompe todas as requests')
  cancelTokenSource = axios.CancelToken.source()
}

export default fetchClient()
