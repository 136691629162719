import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import initialData from 'mocks/demonstrative-faq.json'

export const getFrequentlyAskedQuestions = async () => {
  const response = await axios.get('/api/demonstrative/faq', { params: { query } })
  return response.data
}

export const useDemonstrativeFaq = () =>
  useQuery(['get-demonstrative-faq'], getFrequentlyAskedQuestions, { initialData })
