const domain = process.env.VITTUDE_OFFLINE_URL
const currency = 'BRL'

const clearEcommerceObject = () => {
  typeof window !== undefined && window && window.dataLayer?.push({ ecommerce: null })
}

export const sendViewItemListEvent = ({ impressions, itemListId, itemListName }) => {
  clearEcommerceObject()
  dataLayer?.push({
    event: 'view_item_list',
    domain: domain,
    ecommerce: {
      currencyCode: currency,
      item_list_id: itemListId,
      item_list_name: itemListName,
      impressions: impressions
    }
  })
}

export const sendSelectItemEvent = professionalData => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'select_item',
    domain: domain,
    ecommerce: {
      items: professionalData
    }
  })
}

export const sendViewItemEvent = (professionalData, value) => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'view_item',
    domain: domain,
    ecommerce: {
      currency: currency,
      value: value,
      items: professionalData
    }
  })
}

export const sendAddToCartEvent = (professionalData, value) => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'add_to_cart',
    domain: domain,
    ecommerce: {
      currency: currency,
      value: value,
      items: professionalData
    }
  })
}

export const sendBeginCheckoutEvent = (checkoutData, value) => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'begin_checkout',
    domain: domain,
    ecommerce: {
      currency: currency,
      value: value,
      coupon: '',
      items: checkoutData
    }
  })
}

export const sendHomeViewEvent = ({ location, referrer, title }) => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'home_view',
    domain: domain,
    ecommerce: {
      location: location,
      referrer: referrer,
      title: title
    }
  })
}
export const sendAddPaymentInfoEvent = (checkoutDataForDataLayer, value, paymentType) => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'add_payment_info',
    domain: domain,
    ecommerce: {
      payment_type: paymentType,
      value: value,
      items: checkoutDataForDataLayer
    }
  })
}

export const favoriteProfessional = ({ favoriteEvent, professionalId, professionalSlug }) => {
  clearEcommerceObject()

  dataLayer.push({
    event: favoriteEvent,
    ecommerce: {
      id: professionalId,
      slug: professionalSlug
    }
  })
}

export const sendPurchaseEvent = data => {
  clearEcommerceObject()
  dataLayer.push(data)
}

export const sendBeaconIncidentsListEvent = incidentsList => {
  clearEcommerceObject()
  dataLayer.push({
    event: 'beacon_click',
    ecommerce: {
      incidents: incidentsList
    }
  })
}

export const sendBeaconIncidentEvent = incident => {
  clearEcommerceObject()
  dataLayer.push({
    event: 'beacon_incident_click',
    ecommerce: {
      incident: incident
    }
  })
}

export const sendSearchOnDemonstrativeFaq = ({ id, slug, query }) => {
  clearEcommerceObject()
  window.dataLayer?.push({
    event: 'search_on_demonstrative_faq',
    domain,
    ecommerce: {
      id,
      slug,
      query
    }
  })
}

export const sendSingleClickPurchase = ({ items, value }) => {
  clearEcommerceObject()

  dataLayer?.push?.({
    event: 'single_click_purchase',
    eccomerce: {
      currency,
      items,
      value
    }
  })
}

export const sendMultipleScheduling = ({ items, value }) => {
  clearEcommerceObject()

  dataLayer?.push?.({
    event: 'multiple_scheduling_purchase',
    eccomerce: {
      currency,
      items,
      value
    }
  })
}

export const sendInitialPulseForm = userId => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'start_progress_pulse_collect',
    domain,
    user_id_collect: userId
  })
}

export const sendFinishPulseForm = userId => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'finish_progress_pulse_collect',
    domain,
    user_id_collect: userId
  })
}

export const sendInitialCheckupForm = userId => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'start_recurrent_checkup_collect',
    domain,
    user_id_collect: userId
  })
}

export const sendFinishCheckupForm = userId => {
  clearEcommerceObject()

  dataLayer.push({
    event: 'finish_recurrent_checkup_collect',
    domain,
    user_id_collect: userId
  })
}
