import styled from 'styled-components'
import {
  space,
  layout,
  color,
  flexbox,
  border,
  shadow,
  position,
  background,
  grid,
  typography,
  system
} from 'styled-system'
import propTypes from '@styled-system/prop-types'

const Row = styled.div(
  {
    display: 'flex'
  },
  flexbox,
  space,
  layout,
  color,
  border,
  shadow,
  position,
  background,
  grid,
  typography,
  system({ cursor: true })
)

Row.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.border,
  ...propTypes.borders,
  ...propTypes.shadow,
  ...propTypes.position,
  ...propTypes.background,
  ...propTypes.grid
}

export { Row }
