import axios from 'axios'
import api from './api'

export const vittudeCareAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CARE_API,
  headers: {
    Accept: 'application/json'
  }
})

const getPendingPatientsCount = (professionalId, questionsIds) =>
  api.post(
    'v0_1/professional/look/patients/pending_count',
    {
      professional_id: professionalId,
      questions: questionsIds
    },
    {
      baseURL: process.env.NEXT_PUBLIC_CARE_API
    }
  )

const getCarePatientList = async (professionalId, questionsIds) =>
  await api.post(
    'v0_1/professional/look/patients',
    {
      professional_id: professionalId,
      questions: questionsIds
    },
    {
      baseURL: process.env.NEXT_PUBLIC_CARE_API
    }
  )

const getCarePatientAnswers = async ({
  professionalId,
  questionsIds,
  questionnaireId,
  patientId
}) =>
  await api.post(
    'v0_1/professional/look/patient',
    {
      professional_id: professionalId,
      questionnaire_id: questionnaireId,
      patient_id: patientId,
      questions: questionsIds
    },
    {
      baseURL: process.env.NEXT_PUBLIC_CARE_API
    }
  )

const registerPsychologistViewAnswers = params =>
  api.post('v0_1/professional/look/answers', params, {
    baseURL: process.env.NEXT_PUBLIC_CARE_API
  })

const getPatientHistory = ({ patientId, professionalId, questionsIds }) =>
  api.post(
    'v0_1/professional/look/patient/history',
    {
      patient_id: patientId,
      professional_id: professionalId,
      questions: questionsIds
    },
    {
      baseURL: process.env.NEXT_PUBLIC_CARE_API
    }
  )

const getUserRespondedForm = userId =>
  vittudeCareAPI.get('v0_1/users/responded-form', {
    params: {
      user_id: userId,
      form_type: 'PROGRAM'
    }
  })

const respondProgramForm = async (userId, formId, responseId) =>
  await vittudeCareAPI.post('v0_1/users/program/response', null, {
    params: {
      user_id: userId,
      form_id: formId,
      response_id: responseId
    }
  })

export {
  getPendingPatientsCount,
  getCarePatientList,
  getCarePatientAnswers,
  registerPsychologistViewAnswers,
  getPatientHistory,
  getUserRespondedForm,
  respondProgramForm
}

export default vittudeCareAPI
