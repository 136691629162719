import moment from 'moment'
import { parseCookies } from 'nookies'
import { useUser } from 'providers/UserProvider'
import { getProfile } from 'services/auth'
import { getDataContractId } from 'utils/helpers'

export const useSearchUrlWithContractId = () => {
  const cookies = parseCookies()
  const { user, benefits } = useUser()

  const dataContractId = getDataContractId()
  const startDate = moment().format('YYYY-MM-DD')
  const currentBenefit = benefits?.contracts?.find(benefit => benefit.is_active)

  const contractId =
    user?.contract?.id ||
    currentBenefit?.contract_id ||
    cookies?.contractId ||
    cookies?.['@vittude:user']?.contract?.id ||
    dataContractId?.integration?.contract?.id ||
    getProfile()?.contract?.id

  const searchParam = contractId ? `contract_id=${contractId}` : `startDate=${startDate}`
  const searchUrl = `/busca?${searchParam}`

  return { getSearchUrlWithContractId: () => searchUrl }
}
