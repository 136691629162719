import { useRouter } from 'next/router'
import React, { useState, useEffect, useContext } from 'react'
import CouponContext from 'context/couponContext'

import { getProfile, getRefreshToken, getToken, getUser } from 'services/auth'
import { getCouponValue } from 'services/coupons'

import { getDataContractId } from 'utils/helpers'
import { useFeatureFlags } from 'hooks'
import { parseCookies } from 'nookies'

const COUPON = 'PRIMEIRACONSULTA'

const CouponProvider = ({ children }) => {
  const [coupon, setCoupon] = useState(null)
  const [isUserB2B, setIsUserB2B] = useState(false)
  const [isFirstConsultation, setIsFirstConsultation] = useState(false)
  const [isCheckoutValidToApplyCoupon, setIsCheckoutValidToApplyCoupon] = useState(false)

  const { pathname } = useRouter()
  const { frontend_is_accessible_consultation_banner_visible } = useFeatureFlags(
    'frontend_is_accessible_consultation_banner_visible'
  )

  const profile = getProfile()
  const refreshToken = getRefreshToken()
  const token = getToken()
  const dataContractId = getDataContractId()

  useEffect(() => {
    ;(async () => {
      const storagedCoupon = process.browser && localStorage.getItem('@vittude:promoCoupon')
      if (storagedCoupon) {
        setCoupon(JSON.parse(storagedCoupon))
      } else {
        const res = await getCouponValue({
          coupon: COUPON
        })
        setCoupon(res.data)
        process.browser && localStorage.setItem('@vittude:promoCoupon', JSON.stringify(res.data))
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const isUserLoggedIn = profile && refreshToken && token

      if (isUserLoggedIn) {
        if (profile?.id && profile?.role === 'patient') {
          if (profile?.contract !== null) {
            setIsUserB2B(true)
          }

          if (profile?.is_first_appointment) {
            setIsFirstConsultation(profile?.is_first_appointment)
          }
        }
      } else if (dataContractId) {
        setIsUserB2B(true)
      } else {
        setIsUserB2B(false)
        setIsFirstConsultation(true)
      }
    })()
  }, [pathname, profile, refreshToken, token, dataContractId])

  useEffect(() => {
    if (
      pathname === '/agendar-consultas/finalizar-pagamento' &&
      isFirstConsultation &&
      !isUserB2B &&
      frontend_is_accessible_consultation_banner_visible
    ) {
      setIsCheckoutValidToApplyCoupon(true)
    } else {
      setIsCheckoutValidToApplyCoupon(false)
    }
  }, [pathname, isFirstConsultation, isUserB2B, isCheckoutValidToApplyCoupon])

  return (
    <CouponContext.Provider
      value={{
        coupon,
        isFirstConsultation,
        isUserB2B,
        isCheckoutValidToApplyCoupon
      }}
    >
      {children}
    </CouponContext.Provider>
  )
}

const useCoupon = () => {
  const context = useContext(CouponContext)
  if (!context) {
    throw new Error('useCoupon must be used within a CouponProvider')
  }
  return context
}

export { CouponProvider, useCoupon }
