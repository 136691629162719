import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const useAccountSegments = definitions =>
  useQuery(
    ['get-account-segments', definitions],
    async () => {
      const payload = await axios.post('/api/firebase/get-account-segments', {
        definitions
      })

      return payload.data
    },
    {
      initialData: []
    }
  )
