import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

import api from 'services/api'

moment.locale('pt-br')

export const getProfessionalBySlug = (
  slug,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Sao_Paulo',
  initial_date /*eslint-disable-line */,
  final_date /*eslint-disable-line */,
  virtual,
  scheduleId,
  no_lead_time /*eslint-disable-line */,
  contract_id = null
) => {
  let json

  const initial = initial_date || moment.utc().format('YYYYMMDD') /*eslint-disable-line */
  let params = {}
  const tz = timezone

  return new Promise((resolve, reject) => {
    api
      .post('/professionals/' + slug + '/profile/', params)
      .then(response => {
        json = response.data
        if (final_date) {
          /*eslint-disable-line */
          params = {
            date_from: initial /*eslint-disable-line */,
            date_to: final_date /*eslint-disable-line */,
            timezone: tz
          }
        } else {
          params = {
            date_from: initial,
            count: 5,
            timezone: tz
          }
        }
        params.no_lead_time = no_lead_time /*eslint-disable-line */
        if (virtual) {
          params.virtual = virtual
        } else {
          if (json.prices_clinic.currency && !json.prices_teleconf.currency) {
            params.virtual = false
          } else if (!json.prices_clinic.currency && json.prices_teleconf.currency) {
            params.virtual = true
          } else {
            params.virtual = true
          }
        }

        if (contract_id) {
          params.contract_id = contract_id
        }

        if (scheduleId) {
          params.schedule_id = scheduleId
        }

        api
          .post('/professionals/' + slug + '/get_schedule/', params)
          .then(response => {
            let schedule = response.data.content.available_times
            let prof_address_slug = response.data.content.prof_address /*eslint-disable-line */
            let city = response.data.content.city
            let state = response.data.content.state
            let price = response.data.content.price
            let contract_price = response.data.content.contract_price
            let location = response.data.content.location
            let schedule_id = response.data.content.schedule_id /*eslint-disable-line */
            let date

            let index

            let m

            let dates = []
            json = {
              ...json,
              schedule: {},
              prof_address_slug: prof_address_slug,
              price: price,
              contract_price: contract_price,
              location: location,
              schedule_id: schedule_id,
              state: state,
              city: city
            }
            if (Object.keys(schedule).length === 0) {
              schedule[moment.utc().format('YYYYMMDD')] = []
            }
            Object.keys(schedule).forEach(function(dateKey, k) {
              dates.push(dateKey)
              schedule[dateKey].forEach(function(timeObj, y) {
                json.duration = timeObj.duration
                date = moment.utc(timeObj.date + timeObj.time, 'YYYYMMDDHH:mm')
                index = date.format('YYYYMMDD')
                if (!json.schedule[index]) {
                  json.schedule[index] = [schedule[dateKey][y]]
                } else {
                  json.schedule[index].push(schedule[dateKey][y])
                }
                json.schedule[index] /*eslint-disable-line */[
                  json.schedule[index].length - 1
                ].time = date.format('HH:mm')
                json.schedule[index] /*eslint-disable-line */[
                  json.schedule[index].length - 1
                ].date = index
              })
            })
            var today = +params.date_from ? +params.date_from : +moment.utc().format('YYYYMMDD')
            dates = Array.from(new Set(dates)).sort()
            json.dates = dates
              .map(function(e, i) {
                if (+e >= today) {
                  m = moment.utc(e, 'YYYYMMDD')
                  if (!json.schedule[e]) {
                    json.schedule[e] = []
                  }
                  return {
                    weekday: moment.weekdays(m.day()).split('-')[0],
                    date: m.format('DD MMMM'),
                    timestamp: e
                  }
                }
              })
              .filter(Boolean)
            while (json.dates.length < 7 && json.dates.length > 0) {
              m = moment.utc(json.dates[json.dates.length - 1].timestamp).add(1, 'days')
              json.dates.push({
                weekday: moment.weekdays(m.day()).split('-')[0],
                date: m.format('DD MMMM'),
                timestamp: m.format('YYYYMMDD')
              })
            }
            api
              .get('/professionals/' + slug + '/get_evaluations_paged/')
              .then(response => {
                json.evaluations = response.data.data
                json.evaluationsCount = response.data.count
                if (json.evaluations) {
                  json.evaluations.forEach(function(e, i) {
                    json.evaluations[i].date = moment.unix(e.date).format('DD/MM/YYYY')
                  })
                } else {
                  json.evaluations = []
                  json.evaluationsCount = 0
                }
                resolve(json)
              })
              .catch(msg => {
                reject(msg)
              })
          })
          .catch(msg => {
            reject(msg)
          })
      })
      .catch(msg => {
        reject(msg)
      })
  })
}

export const setProfessionalCNPJ = ({ cnpj, id }) =>
  api.post(`/professional/${id}/set_cnpj/`, { cnpj })

export const getScheduleBySlug = async (
  slug,
  timezone,
  initial_date,
  final_date,
  virtual,
  scheduleId,
  no_lead_time,
  contract_id = null,
  consultations
) => {
  const initial = initial_date || moment.utc().format('YYYYMMDD')
  const params = {
    date_from: initial,
    timezone
  }
  if (final_date) {
    params.date_to = final_date
  } else {
    params.count = 5
  }
  params.no_lead_time = no_lead_time
  if (typeof virtual !== 'undefined') {
    params.virtual = virtual
  } else {
    const pricesClinicCurrency = json.prices_clinic.currency
    const pricesTeleconfCurrency = json.prices_teleconf.currency
    params.virtual = Boolean(pricesClinicCurrency && !pricesTeleconfCurrency)
  }
  if (typeof scheduleId !== 'undefined') {
    params.schedule_id = scheduleId
  }
  if (contract_id) {
    params.contract_id = contract_id
  }
  if (consultations) {
    params.consultations = consultations
  }

  try {
    const response = await api.post(`/professionals/${slug}/get_schedule/`, params)
    const {
      available_times: schedule,
      prof_address: profAddressSlug,
      city,
      state,
      price,
      contract_price: contractPrice,
      location,
      schedule_id: scheduleId
    } = response.data.content
    const json = {
      schedule: {},
      prof_address_slug: profAddressSlug,
      price,
      contract_price: contractPrice,
      location,
      schedule_id: scheduleId,
      state,
      city
    }
    if (Object.keys(schedule).length === 0) {
      schedule[moment.utc().format('YYYYMMDD')] = []
    }
    const dates = Object.keys(schedule)
    const today = +params.date_from || +moment.utc().format('YYYYMMDD')
    json.dates = dates
      .filter(dateKey => +dateKey >= today)
      .sort()
      .map(dateKey => {
        const m = moment.utc(dateKey, 'YYYYMMDD')
        const weekday = moment.weekdays(m.day()).split('-')[0]
        const date = m.format('DD MMMM')
        const timestamp = dateKey
        const times = schedule[dateKey]
        json.schedule[dateKey] = times.map(timeObj => {
          const date = moment.utc(timeObj.date + timeObj.time, 'YYYYMMDDHH:mm')
          const index = date.format('YYYYMMDD')
          return {
            ...timeObj,
            time: date.format('HH:mm'),
            date: index
          }
        })
        return {
          weekday,
          date,
          timestamp
        }
      })
    while (json.dates.length < 7 && json.dates.length > 0) {
      const lastTimestamp = json.dates[json.dates.length - 1].timestamp
      const m = moment.utc(lastTimestamp, 'YYYYMMDD').add(1, 'days')
      const weekday = moment.weekdays(m.day()).split('-')[0]
      const date = m.format('DD MMMM')
      const timestamp = m.format('YYYYMMDD')
      json.dates.push({
        weekday,
        date,
        timestamp
      })
      json.schedule[timestamp] = []
    }
    return json
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getPaymentMethodsInfo = async (date, time, timezone) =>
  await api.get(`payments/allowed-payment-methods/${date}/${time}?timezone=${timezone}`)

export const getProfessionalInfoBySlug = async slug =>
  await api.post(`/professionals/${slug}/profile/`)

export const getScheduleByProfessionalSlug = async (
  slug,
  date_from,
  date_to = null,
  schedule_id = null,
  no_lead_time = null,
  contract_id = null,
  virtual = null,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Sao_Paulo',
  count = 5
) => {
  const params = {
    date_from,
    timezone,
    ...(date_to && { date_to }),
    ...(!schedule_id && virtual && { virtual }),
    ...(schedule_id && { schedule_id }),
    ...(no_lead_time && { no_lead_time }),
    ...(contract_id && { contract_id }),
    ...(!date_to && { count })
  }

  return api.post(`/professionals/${slug}/get_schedule/`, params)
}

export const initialPaymentMethodsInfoParams = {
  date: moment().format('YYYYMMDD'),
  time: moment().format('HH:MM')
}

export const usePaymentMethodsInfo = (
  { date, time, timezone } = initialPaymentMethodsInfoParams,
  options = {}
) =>
  useQuery(['get-payment-methods-info'], () => getPaymentMethodsInfo(date, time, timezone), {
    ...options,
    select: ({ data }) => data?.content?.payment_method || {},
    onSuccess: async paymentMethod => {
      await options?.onSuccess?.()
      sessionStorage.setItem('next_bank_date', paymentMethod.bank_slip.available_at)
    }
  })
