const SET_SELECTED_CLINIC = 'SET_SELECTED_CLINIC'
const INITIAL_STATE = {
  configValue: 'VIRTUAL'
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SELECTED_CLINIC:
      return {
        ...state,
        configValue: action.payload
      }
    default:
      return state
  }
}

export const setSelectedClinic = configValue => {
  return dispatch => {
    dispatch({ type: SET_SELECTED_CLINIC, payload: configValue })
  }
}
