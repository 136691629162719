import api from './api'

export const getBenefits = user_id => user_id && api.get(`/patient/${user_id}/allowed_contracts/`)

export const setBenefits = (user_id, contract_id) =>
  api.post(`/patient/${user_id}/change_contract/?contract=${contract_id}`)

export const disableBenefit = (user_id, benefit_id) =>
  api.delete(`/patient/${user_id}/remove_contract/?contract=${benefit_id}`)

export const getBenefitsUses = () => api.get('/payment-method/exclusive/')
