import { useEffect } from 'react'

export const useBodyScrollLock = (lockBodyScroll, params) => {
  useEffect(() => {
    if (window) {
      document.body.style.overflow = lockBodyScroll ? 'hidden' : 'auto'
    }
    return () => (document.body.style.overflow = 'auto')
  }, [...(params || [])])
}
