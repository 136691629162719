import { sendViewItemListEvent } from 'components/shared/DataLayer'
import { findPsychologists } from '../../services/handleRequests'

const LOADING_PSYCHOS = 'LOADING_PSYCHOS'
const PSYCHOS = 'PSYCHOS'
const PSYCHOS_SHOW_MORE = 'PSYCHOS_SHOW_MORE'
const ERROR_PSYCHOS = 'ERROR_PSYCHOS'
const UPDATE_BTN_FILTER = 'UPDATE_BTN_FILTER'
const UPDATE_FILTER = 'UPDATE_FILTER'
const RESET_PSYCHOLOGIST = 'RESET_PSYCHOLOGIST'
const CLEAR_PSYCHOLOGISTS = 'CLEAR_PSYCHOLOGISTS'
const CANCEL_ACCOUNT = 'CANCEL_ACCOUNT'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  max_price_overall: process.env.FILTER_VALUE_MAX,
  min_price_overall: process.env.FILTER_VALUE_MIN,
  count: 0,
  page: 1,
  next: null,
  previous: null,
  next_bank_slip_day_available: null,
  wants_to_cancel_account: false
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING_PSYCHOS:
      return {
        ...state,
        loading: action.payload
      }
    case RESET_PSYCHOLOGIST:
      return INITIAL_STATE
    case PSYCHOS:
      return {
        ...state,
        data: action.payload.results,
        count: action.payload.count,
        page: action.payload.page,
        next: action.payload.next,
        previous: action.payload.previous,
        next_bank_slip_day_available: action.payload.next_bank_slip_day_available
      }
    case PSYCHOS_SHOW_MORE:
      return {
        ...state,
        data: state.data.concat(action.payload.results),
        count: action.payload.count,
        page: action.payload.page,
        next: action.payload.next,
        previous: action.payload.previous,
        next_bank_slip_day_available: action.payload.next_bank_slip_day_available
      }
    case ERROR_PSYCHOS:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case CLEAR_PSYCHOLOGISTS:
      return {
        ...state,
        data: []
      }

    case CANCEL_ACCOUNT:
      return {
        ...state,
        wants_to_cancel_account: action.value
      }

    default:
      return state
  }
}

export const isLoading = flag => dispatch => dispatch({ type: LOADING_PSYCHOS, payload: flag })

export const resetPsychologist = () => dispatch => dispatch({ type: RESET_PSYCHOLOGIST })

export const loadPsychos = (params, isPaginating = false, page) => async dispatch => {
  if (!isPaginating) {
    dispatch({ type: RESET_PSYCHOLOGIST })
  }

  dispatch({ type: LOADING_PSYCHOS, payload: true })

  try {
    const psychologists = await findPsychologists(params)

    dispatch({ type: isPaginating ? PSYCHOS_SHOW_MORE : PSYCHOS, payload: psychologists })
    dispatch({
      type: UPDATE_FILTER,
      payload: { obj: { activeBtnFilter: false, page: psychologists.page } }
    })

    if (isPaginating) {
      dispatch({ type: UPDATE_BTN_FILTER, payload: false })
    }

    const impressions = psychologists?.results.map((psychologist, index) => ({
      item_id: psychologist.id,
      item_name: psychologist.name,
      page: page,
      index: index,
      price: psychologist.prices.max,
      quantity: 1
    }))

    sendViewItemListEvent({
      impressions,
      itemListId: 'psicologos_busca',
      itemListName: 'Psicólogos da Busca'
    })
  } catch (error) {
    dispatch({ type: ERROR_PSYCHOS, payload: error })
  } finally {
    dispatch({ type: LOADING_PSYCHOS, payload: false })
  }
}

export const clearPsychologists = () => dispatch => dispatch({ type: CLEAR_PSYCHOLOGISTS })

export const cancelAccount = value => dispatch => dispatch({ type: CANCEL_ACCOUNT, value })
