import { useCallback } from 'react'

import { findKeywords, withoutSpecialChars } from 'utils/helpers'

const EMPTY_SPACE = ' '

export const useHighlighter = (words = []) =>
  useCallback(
    text => {
      const keywords = findKeywords(text, words)

      return text
        .split(EMPTY_SPACE)
        .map(text => {
          const sanitized = withoutSpecialChars(text)

          if (!keywords.includes(sanitized)) {
            return text
          }

          return `<mark>${text}</mark>`
        })
        .join(EMPTY_SPACE)
    },
    [words]
  )
