import { waitFor } from 'utils/helpers'

const defaultWithDelaiedRetryBackoffConfig = {
  timeoutInMilliseconds: 1000
}

export const withDelayedRetryBackoff = async (
  callback = async () => {},
  { timeoutInMilliseconds } = defaultWithDelaiedRetryBackoffConfig
) => {
  try {
    return await callback()
  } catch (exception) {
    await waitFor(timeoutInMilliseconds)
    return await callback()
  }
}
