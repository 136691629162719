import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getProfile } from '../services/auth'
import { addPartnership } from '../services/userService'

import { setPartnership } from '../redux/modules/consultation'

export const usePartnership = partnership => {
  const dispatch = useDispatch()

  useEffect(() => {
    const profile = getProfile()

    if (profile && partnership) {
      dispatch(setPartnership(partnership))
      addPartnership(partnership, profile.id)
    }
  }, [])
}
